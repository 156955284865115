<script>
// import this.$axios.get from "this.$axios.get";
import Dropdown from "../components/dropdown.vue";
import { mask } from "vue-the-mask";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import Message from "../components/mensagem/Mensagem.vue";
import Popup from "../components/cadastroUsuario/popUp.vue";
import BotoesTempo from "../components/cadastroUsuario/botoesTempo.vue";
import SelectSearch from "@/components/Select-Search/SelectSearch.vue";
import {
  getUsuarioCadastrado,
  postCadastroBanco,
} from "../components/cadastroUsuario/api.js";
import { limpaMascara } from "../components/cadastroUsuario/util.js";
import DisparoSeletor from "../components/cadastroUsuario/DisparoSeletor.vue";
import { AlertDataSend } from "../components/alert";
import ConfirmationModalVue from "../components/Comfirmacao/ConfirmationModal.vue";
import CadastroUsuario from "./CadastroUsuario.vue";
import instrucoesVue from "../components/help/instrucoes.vue";
import Expediente from "../components/Expediente/Expediente.vue";
import SelectDatasend from "@/components/Select-Search/SelectDatasend.vue";
import popupDatasend from "../components/popupDatasend/popupDatasend.vue";
import MultiSelectVue from "../components/MultiSelect/MultiSelect.vue";
import MultiDatasend from "../components/MultiSelect/MultiDatasend.vue";

const url = process.env.VUE_APP_API_URL;
const urlSocket = process.env.VUE_APP_API_URL_SOCKET;
const urlComunicationQlik = process.env.VUE_APP_API_URL_QLIK;
const urlComunicationShooring = process.env.VUE_APP_API_URL_SHOOTING;

export default {
  directives: { mask },

  setup() {
    return { v$: useVuelidate() };
  },
  // watch:{
  //     loading(stateLoading) {
  //       if(!stateLoading){
  //         this.loading = false
  //       }else{
  //         this.loading = true
  //       }
  //     }
  // },
  components: {
    BotoesTempo,
    Popup,
    Dropdown,
    Message,
    Expediente,
    DisparoSeletor,
    ConfirmationModalVue,
    CadastroUsuario,
    instrucoesVue,
    SelectSearch,
    SelectDatasend,
    popupDatasend,
    MultiSelectVue,
    MultiDatasend,
  },
  data() {
    return {
      idUsuario: null,
      idEmpresa: null,

      /* formulario*/

      nome: null,
      email: null,
      numeroWhatsapp: "",
      assistente: "",
      msg: null,
      campoTitle: "Selecione",
      campo: null,
      campos: null,
      conteudoTitle: "Selecione",
      conteudo: null,
      conteudos: null,
      bookmarks: "",
      bookmark: "",
      objetoTitle: "Selecione",
      objetos: "",
      objeto: "",
      tipo: null,
      painelTitle: "Selecione",
      paineis: "",
      painel: "",
      sheetTitle: "Selecione",
      expedienteTitle: "Adicionar Expediente",
      sheets: "",
      sheet: "",
      filtro: [],
      favorito: false,
      editingCampo: true,
      editingCampoText: {},
      editingConteudo: true,
      editingConteudoText: {},
      indexConteudo: null,
      indexcampo: null,
      PopupStatus: false,
      paginaAtual: 1,
      showLoadButton: true,
      addExpediente: true,
      loading: false,
      todos_itens: false,
      temItensSelecionados: false,
      expedientesCadastrados: " ",
      filtrarexpediente: [],
      idExpediente: null,
      filtros: [],
      expedienteRemove: false,
      bookmarkTitle: "Selecione",

      showHoursPopUp: false,

      idDisparo: null,
      mostrarCalendar: true,
      userToDelete: null,
      showDeleteConfirmation: false,
      modelConfimation: false,
      disparo: null,
      spinner: null,
      mostrarPoup: false,
      revoverFiltros: true,
      disparaFiltro: false,
      pagesDropdownOpen: true,
      blockFieldAndContent: false,
      blockBookmark: false,
      removeSelecao: false,
      DropdownOpen: true,
      letter: "A",
      isPdfIcon: true,
      iconsSelecionados: {},
      display: true,
      positions: [],
    };
  },

  validations() {
    return {
      nome: { required },
      email: {},
      numeroWhatsapp: { required, minLengthRef: minLength(15) },
      assistente: { required },
    };
  },

  async created() {
    const localStorageObject = await JSON.parse(localStorage.getItem("User"));
    this.idEmpresa = localStorageObject.Id;

    if (this.$route.query.id) {
      this.alreadyRegisteredUser();
    }

    const panel = await this.getPanel();
    this.paineis = panel.data;

    await this.expedienteCadastrado();

    document.addEventListener("keydown", this.handleEscKey);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.handleEscKey);
  },
  computed: {
    iconClass() {
      return this.isPdfIcon
        ? "fa-solid fa-file-pdf"
        : "fa-solid fa-file-circle-xmark";
    },
  },
  methods: {
    async alreadyRegisteredUser() {
      const dadosJacadastrado = await getUsuarioCadastrado(
        this.$route.query.id
      );
      const ordenarFiltro = dadosJacadastrado.filtro.sort((a, b) => {
        let panelComparison = a.painel.title.localeCompare(b.painel.title);

        if (panelComparison === 0) {
          let sheetComparison = a.sheet.title.localeCompare(b.sheet.title);
          if (sheetComparison === 0) {
            return a.objeto.title.localeCompare(b.objeto.title);
          }
          return sheetComparison;
        }
        return panelComparison;
      });
      this.nome = dadosJacadastrado.nome;
      this.email = dadosJacadastrado.email;
      this.numeroWhatsapp = dadosJacadastrado.numeroWhatsapp;
      this.assistente = dadosJacadastrado.assistente;
      this.filtro = ordenarFiltro;
      this.idUsuario = this.$route.query.id;
      this.disparo = dadosJacadastrado.shooting;
      if (dadosJacadastrado.expediente !== null) {
        this.updateExpedienteTitle(dadosJacadastrado.expediente[0]);
      }
      this.validationWhatIcon();
    },
    async addedFavorite(id, sheet) {
      if (sheet == "*****") {
        AlertDataSend("Favoritar somente painel não é possível.");
      } else {
        const idqlik = { id: id };
        await this.$axios.post(url + "/favorite", idqlik);
        location.reload();
      }
    },
    selecionarTodos() {
      this.filtro.forEach((item) => {
        item.selecionado = !this.todos_itens;
      });
      this.atualizarItensSelecionados();
    },
    atualizarItensSelecionados() {
      this.temItensSelecionados = this.filtro.some((item) => item.selecionado);
    },
    deleteFiltersConfirmed() {
      this.filtro = this.filtro.filter((item) => !item.selecionado);
      this.atualizarItensSelecionados();
      this.mostrarPoup = false;
      this.modelConfimation = false;
      this.showDeleteConfirmation = false;
      this.todos_itens = false;
    },
    deleteFiltersCanceled() {
      this.mostrarPoup = false;
      this.modelConfimation = false;
      this.showDeleteConfirmation = false;
    },
    handleEscKey(event) {
      if (event.key === "Escape" && !this.PopupStatus) {
        this.$router.push("/usuario");
      }
    },
    excluirSelecionados() {
      const filtrosSelecionados = this.filtro.filter(
        (item) => item.selecionado
      );
      if (filtrosSelecionados.length > 0) {
        this.modelConfimation = true;
        this.showDeleteConfirmation = false;
      }
    },
    limparPastaEObjeto() {
      if (this.sheets) {
        this.sheets = null;
        this.sheet = "";
        this.sheetTitle = "Selecione";
        this.objetos = "";
        this.objeto = "";
        this.objetoTitle = "Selecione";
        this.campos = null;
        this.conteudos = null;
        this.campoTitle = "Selecione";
        this.conteudoTitle = "Selecione";
        this.campo = null;
        this.conteudo = null;
        this.bookmark = "";
        this.bookmarks = "";
        this.bookmarkTitle = "Selecione";
        this.blockFieldAndContent = false;
        this.blockBookmark = false;
      }
    },
    limparObjeto() {
      if (this.objetos) {
        this.objetos = "";
        this.objeto = "";
        this.objetoTitle = "Selecione";
      }
    },
    liparConteudo() {
      if (this.conteudos) {
        this.conteudos = "";
        this.conteudo = "";
        this.conteudoTitle = "Selecione";
      }
    },
    limparBookmark() {
      if (this.bookmarks) {
        this.bookmarks = "";
        this.bookmark = "";
        this.bookmarks = "Selecione";
      }
    },
    abrirPopup(idIndex, idFiltro, sheet) {
      if (sheet == "*****") {
        AlertDataSend("Agendar somente painel não é possível.");
      } else {
        this.PopupStatus = true;
        this.idDisparo = idFiltro;
      }
    },
    fecharPopup() {
      this.$router.push("/cadastrousuario");
      this.PopupStatus = false;
    },
    // deleteUserConfirmed() {
    //   this.filtro.splice(this.userToDelete, 1);
    //   this.showDeleteConfirmation = false;
    // },
    remover_filtro(index) {
      this.filtro.splice(index, 1);
      // this.userToDelete = index;
      // this.showDeleteConfirmation = true;
    },
    // deleteUserCanceled() {
    //   this.showDeleteConfirmation = false;
    // },
    validate() {
      this.v$.$validate();
      if (this.v$.$error) {
        alert("Preencha os campos corretamente");
      }
    },
    async expedienteCadastrado() {
      const localStorageObject = JSON.parse(localStorage.getItem("User"));
      this.idEmpresa = localStorageObject.Id;

      if (this.idEmpresa) {
        try {
          const response = await this.$axios.get(url + "/expediente", {
            params: { id: this.idEmpresa },
          });

          this.filtrarexpediente = response.data;
        } catch (error) {
          console.error("Erro ao carregar os expedientes:", error);
        }
      }
    },
    validar_cadastro() {
      this.v$.$validate();
      if (this.v$.$error) {
        AlertDataSend("Preencha os campos corretamente", alert);
      } else {
        this.enviarCadastroParaBanco();
      }
    },
    updatePainel(option) {
      this.painel = option;
      this.painelTitle = option.title;
    },
    resetPasta() {
      this.sheetTitle = "Selecione";
      this.atualizarField()
    },
    updatePasta(option) {
      this.sheet = option;
      this.sheetTitle = option.title;
    },
    resetObjeto() {
      this.objetoTitle = "Selecione";
    },
    resetBookmark() {
      this.bookmark = "";
      this.bookmarkTitle = "Selecione";
      this.blockFieldAndContent = false;
    },
    updateObjeto(option) {
      this.objeto = option;
      this.objetoTitle = option.title;
    },
    resetCampo() {
      this.campo = "";
      this.campoTitle = "Selecione";
      this.blockBookmark = false;
      this.liparConteudo()
    },
    updateCampo(option) {
      this.campo = option;
      this.campoTitle = option.title;
      this.blockBookmark = true;
    },
    async updateBookmark(option) {
      this.bookmark = await option;
      this.bookmarkTitle = this.bookmark.title;
      this.blockFieldAndContent = true;
    },
    resetConteudo() {
      this.conteudo = "";
      this.conteudoTitle = "Selecione";
    },
    updateConteudo(option) {
      this.conteudo = option;
      this.conteudoTitle = option.title;
    },
    updateExpedienteTitle(option) {
      if (option >= 0) {
        this.expedienteRemove = true;
      } else {
        this.expedienteRemove = true;
      }
      this.expedienteTitle = option.nome;
      this.idExpediente = option.id;
    },
    async adcionar() {
      // Gambiarra, pretendo resolver futuramente
      await new Promise((res) => setTimeout(res, 10));
      switch (true) {
        case this.sheet === "":
          this.sheet = { title: "*****" };
        case this.objeto === "":
          this.objeto = { title: "*****" };
          break;
      }
      if (!this.campo || this.campo === "") {
        this.campo = { title: null };
      }
      if (!this.conteudo || this.conteudo === "") {
        this.conteudo = { title: null };
      }
      if (!this.bookmark) {
        this.bookmark = { title: "*****" };
      }
      if (this.painel === "") {
        AlertDataSend("Por favor, selecione um painel.", "alert");
      }
      if (this.campo.title && !this.conteudo.title) {
        AlertDataSend("Não é possível cadastrar campo sem conteúdo");
      } else {
        const dados = {
          painel: this.painel,
          sheet: this.sheet,
          objeto: this.objeto,
          campo: this.campo,
          conteudo: this.conteudo,
          bookmark: this.bookmark,
          favorito: false,
        };
        const verify = this.filtro.some((item) => {
          if (
            item.painel.title === dados.painel.title &&
            item.sheet.title === dados.sheet.title &&
            item.objeto.title === dados.objeto.title &&
            item.campo.title === dados.campo.title &&
            item.conteudo.title === dados.conteudo.title &&
            item.bookmark.title === dados.bookmark.title
          ) {
            return true;
          }
          return false;
        });
        if (!verify) {
          this.filtro.unshift(dados);
        } else {
          AlertDataSend("Filtro já cadastrado");
        }
        // const verifyExisting = this.filtro.some((item) => {
        //   if (item.painel.title === dados.painel.title &&
        //       item.sheet.title === dados.sheet.title &&
        //       item.objeto.title === dados.objeto.title &&
        //       ((item.campo.title !== "" && dados.campo.title === "") ||
        //       (item.conteudo.title !== "" && dados.conteudo.title === ""))) {
        //       return true;
        //   }
        //   return false;

        //  });

        // const verifyDuplicate = this.filtro.some((item) => {
        //     if (item.painel.title === dados.painel.title &&
        //         item.sheet.title === dados.sheet.title &&
        //         item.objeto.title === dados.objeto.title &&
        //         item.campo.title === dados.campo.title &&
        //         item.conteudo.title === dados.conteudo.title) {
        //         return true;
        //     }
        //     return false;
        // });

        // if (!verifyExisting ) {
        //     this.filtro.unshift(dados);
        // } else if (verifyDuplicate) {
        //     AlertDataSend("Filtro já cadastrado com os mesmos dados.");
        // } else {
        //     // AlertDataSend("Filtro já cadastrado com uma configuração diferente.");
        // }
      }
    },
    verifyReceivedThetextSearch(object) {
      if (object === "") {
        this.sheet = { title: "*****" };
        this.sheetTitle = "Selecione";
      }
    },
    async enviarCadastroParaBancoViaDisparo() {
      const user = {
        idEmpresa: this.idEmpresa,
        nome: this.nome,
        idusuario: this.idUsuario,
        email: this.email,
        idExpediente: this.idExpediente,
        numeroWhatsapp: limpaMascara(this.numeroWhatsapp),
        filtro: this.filtro,
        status: this.assistente,
      };
      this.v$.$validate();
      if (this.v$.$error) {
        AlertDataSend("Preencha os campos corretamente", alert);
        return;
      }
      const id = await postCadastroBanco(user);
      this.idUsuario = id.data;
      this.$root.$emit("SalvarExpediente");
      if (id && id.response && id.response.status === 409) {
        AlertDataSend("Número já está cadastrado.");
      } else if (!this.$route.query.id) {
        this.idUsuario = id;
        await this.$root.$emit("SalvarExpediente");
        this.$router.push("/usuario");
        await this.$router.push({
          name: "cadastrousuario",
          query: { id: id.data },
        });
        location.reload();
      } else {
        location.reload();
      }
    },
    limparFiltros() {
      let filtroLimpo = [];
      for (const item of this.filtro) {
        if (!item.idLine) {
          filtroLimpo.push(item);
        }
      }
      return filtroLimpo;
    },
    // retirado a verificação de numero repetido
    async enviarCadastroParaBanco() {
      const user = {
        idEmpresa: this.idEmpresa,
        idusuario: this.$route.query.id,
        nome: this.nome,
        email: this.email,
        numeroWhatsapp: limpaMascara(this.numeroWhatsapp),
        idExpediente: this.idExpediente,
        filtro: this.filtro,
        status: this.assistente,
        favorito: false,
      };
      const id = await postCadastroBanco(user);
      this.idUsuario = id;
      this.$root.$emit("SalvarExpediente");
      if (id && id.response && id.response.status === 409) {
        AlertDataSend("Número já está cadastrado.");
      }else{
        this.$router.push("/usuario");
      } 
    },
    async getPanel() {
      const jsonData = { idCompany: this.idEmpresa };
      try {
        const panel = await this.$axios.get(urlComunicationQlik + "/panel/all", {
          params: jsonData,
        });

        return panel;
      } catch (error) {
        AlertDataSend("Não foi possivel carregar os painéis.");
        setTimeout(() => {
          AlertDataSend("Preencha seus dados em conexão");
        }, 2800);
      }
    },
    async getBookmark() {
      const jsonData = { idCompany: this.idEmpresa, idPanel: this.painel.id };
      await this.$axios
        .get(urlComunicationQlik + "/bookmark/all", {
          params: jsonData,
        })
        .then((response) => {
          this.bookmarks = response.data;
        })
        .catch((error) => { });
    },
    async atualizarSheet() {
      this.spinner = true;
      const jsonData = { idCompany: this.idEmpresa, idPanel: this.painel.id };

      const reqSheet = await this.$axios.get(urlComunicationQlik + "/sheet/all", {
        params: jsonData,
      });
      this.sheets = await reqSheet.data;
    },
    async atualizarField() {
      this.spinner = true;
      const jsonData = { idCompany: this.idEmpresa, idPanel: this.painel.id };

      const reqField = await this.$axios.get(urlComunicationQlik + "/field/all", {
        params: jsonData,
      });
      this.campos = await reqField.data;
    },
    async atualizarObject() {
      this.spinner = true;
      const objJson = {
        idCompany: this.idEmpresa,
        idPanel: this.painel.id,
        idSheet: this.sheet.id,
      };

      const reqObject = await this.$axios.get(urlComunicationQlik + "/object/all", {
        params: objJson,
      });
      this.objetos = await reqObject.data;
    },
    // pega os dados do conteudo
    async getContent() {
      this.loading = true
      this.conteudo = "";
      const objJson = {
        idPanel: this.painel.id,
        idField: this.campo.title,
        idCompany: this.idEmpresa,
        qtd: 0,
      };
      const response = await this.$axios.post(
        urlComunicationQlik + "/content/all",
        objJson
      );
      response.data.sort((a, b) => {
        let numA = parseInt(a.title, 10);
        let numB = parseInt(b.title, 10);

        return numA - numB;
      });
      if (!this.conteudos) {
        this.conteudos = response.data;
      } else {
        for (let data of response.data) {
          this.conteudos.push(data);
        }
      }
      this.loading = false
    },
    // pega mais dados concatena os dados é envia pro backend
    async obterMaisConteudos() {
      this.loading = true
      const objJson = {
        idPanel: this.painel.id,
        idField: this.campo.title,
        idCompany: this.idEmpresa,
        qtd: this.conteudos.length,
      };
      try {
        const response = await this.$axios.post(urlComunicationQlik + "/content/all", objJson);
        response.data.sort((a, b) => {
          let numA = parseInt(a.title, 10);
          let numB = parseInt(b.title, 10);

          return numA - numB;
        });

        for (let i = 0; i < response.data.length; i++) {
          this.conteudos.push(response.data[i]);
        }
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    },
    abrirModal() {
      this.disparaFiltro = true;
    },
    // função de Disparar agora/ pode ser melhorada.
    async dispararAgora(id) {
      const filtrosSelecionados = this.filtro.filter(
        (item) => item.selecionado
      );
      const resultFind = filtrosSelecionados.find(
        (it) => it.sheet.title === "*****"
      );

      if (resultFind) {
        AlertDataSend("Não é possível enviar somente painel.");
        return;
      }
      const filtersChosen = filtrosSelecionados.map((it) => {
        return { idFiltro: it.idLine, idUsuario: this.$route.query.id };
      });

      const body = filtersChosen;
      try {
        await this.$axios.post(
          urlComunicationShooring + "/shooting/send-message",
          body
        );
      } catch (error) { }
    },
    envioConfirmed() {
      this.dispararAgora();
      this.desmarcarCheckboxes();
      this.disparaFiltro = false;
      this.todos_itens = false;
      this.temItensSelecionados = false;
    },
    desmarcarCheckboxes() {
      this.filtro.forEach((item) => {
        item.selecionado = false;
      });
    },
    envioCanceled() {
      this.disparaFiltro = false;
      this.todos_itens = false;
    },
    editConteudo(index, event) {
      let newNameConteudo = event.target.value;

      if (newNameConteudo === "") {
        newNameConteudo = null;
      }

      const verify = this.filtro.some(
        (item) =>
          item.conteudo.title === newNameConteudo &&
          item.conteudo.title !== null
      );
      if (verify) {
        AlertDataSend("Filtro já cadastrado");
        event.target.value = this.filtro[index].conteudo.title;
      } else if (newNameConteudo === null) {
        this.filtro[index].campo.title = null;

        this.filtro[index].conteudo.title = newNameConteudo;
      } else {
        this.filtro[index].conteudo.title = newNameConteudo;
      }
    },
    editCampo(index, event) {
      let newNameCampo = event.target.value;

      if (newNameCampo === "") {
        newNameCampo = null;
      }

      const verify = this.filtro.some(
        (item) => item.campo.title === newNameCampo && item.campo.title !== null
      );

      if (verify) {
        AlertDataSend("Filtro já cadastrado");
        event.target.value = this.filtro[index].campo.title;
      } else if (newNameCampo === null) {
        this.filtro[index].conteudo.title = null;

        this.filtro[index].campo.title = newNameCampo;
      } else {
        this.filtro[index].campo.title = newNameCampo;
      }
    },
    saveCampo(it, index) {
      this.editingCampo = true;
      this.indexcampo = index;
    },
    cancelEditCampo(index) {
      this.editingCampo = null;
      this.indexcampo = index;
    },
    saveConteudo(it, index) {
      this.editingConteudo = true;
      this.indexConteudo = index;
    },
    cancelEditConteudo(index) {
      this.editingConteudo = null;
      this.indexConteudo = index;
    },
    marcaSelecionado(data) {
      // if( item.objeto.title === data.objeto.title){
      // }
      if (data.painel) {
        this.filtro.forEach((filtro) => {
          if (filtro.painel.title === data.painel) {
            filtro.selecionado = true;
          }
        });
      } else if (data.pasta) {
        this.filtro.forEach((filtro) => {
          if (filtro.sheet.title === data.pasta) {
            filtro.selecionado = true;
          }
        });
      } else if (data.objeto) {
        this.filtro.forEach((filtro) => {
          if (filtro.objeto.title === data.objeto) {
            filtro.selecionado = true;
          }
        });
      }
      this.atualizarItensSelecionados();
    },
    opcaoSelecionadaEnvio(id, index) {
      this.iconsSelecionados = { ...this.iconsSelecionados, [index]: id };
    },
    validationWhatIcon() {
      this.filtro.map((it) => {
        if (it.tipo_personalizado && it.personalizado_selecionado) {
          if (it.tipo_personalizado === 0) {
            it.icon = "fa-brands fa-pied-piper-pp";
          } else if (it.tipo_personalizado === 1) {
            it.icon = "fa-solid fa-image";
          } else if (it.tipo_personalizado === 2) {
            it.icon = "fa-solid fa-font";
          }
        } else {
          it.icon = "fa-brands fa-pied-piper-pp";
        }
      });
    },
    updateIcon(idIcon, index) {
      if (idIcon === 0) {
        this.filtro[index].personalizado_selecionado = false;
        this.filtro[index].icon = "fa-brands fa-pied-piper-pp";
      } else if (idIcon === 1) {
        this.filtro[index].tipo_personalizado = 1;
        this.filtro[index].personalizado_selecionado = true;
        this.filtro[index].icon = "fa-solid fa-image";
      } else if (idIcon === 2) {
        this.filtro[index].tipo_personalizado = 2;
        this.filtro[index].personalizado_selecionado = true;
        this.filtro[index].icon = "fa-solid fa-font";
      }
    },
  },
};
</script>
<template>
  <main>
    <Popup v-if="PopupStatus" :disparo="disparo" @fecharPopup="fecharPopup" style="position: absolute; z-index: 10"
      :idQlik="idDisparo" :idUser="idUsuario">
    </Popup>
    <div class="cadastrousuario">
      <div class="body">
        <ConfirmationModalVue v-if="disparaFiltro" @confirmed="envioConfirmed" @canceled="envioCanceled"
          :text="'Deseja realmente disparar esses filtros'">
        </ConfirmationModalVue>
        <ConfirmationModalVue v-if="showDeleteConfirmation" @confirmed="deleteUserConfirmed"
          @canceled="deleteUserCanceled" :text="'Deseja realmente excluir esse filtro?'"></ConfirmationModalVue>
        <ConfirmationModalVue v-if="modelConfimation" @confirmed="deleteFiltersConfirmed"
          @canceled="deleteFiltersCanceled" :text="'Deseja realmente excluir esses filtros?'">
        </ConfirmationModalVue>
        <div class="cabecario">
          <h1>
            Cadastrar usuário
          </h1>
          <router-link to="/usuario">
            <button class="btn-voltar">
              <i class="fa-solid fa-x"></i>
            </button>
            <Message :msg="msg" v-show="msg" id="Mensagem" />
          </router-link>
        </div>
        <div class="informations">
          <div class="forms">
            <div>
              <label for="nome">Nome do usuário</label>
              <input :class="[{ error: v$.nome.$error }]" v-model="v$.nome.$model" type="text" id="nome"
                placeholder="Nome" autocomplete="off" />
              <p class="style-obgr" v-if="v$.nome.$error">Campo obrigatório</p>
            </div>
            <div v-if="false">
              <label for="email">E-mail</label>
              <input :class="[{ error: v$.email.$error }]" type="text" id="email" placeholder="exemplo@exemplo.com"
                autocomplete="off" v-model="v$.email.$model" />
            </div>
            <div>
              <label for="numero">Número do Whatsapp</label>
              <input :class="[{ error: v$.numeroWhatsapp.$error }]" type="tel" id="numero" v-mask="'55(##)####-#####'"
                placeholder=" 🇧🇷 (DDD) 0000-0000" autocomplete="off" v-model="v$.numeroWhatsapp.$model" />
              <p class="style-obgr" v-if="v$.numeroWhatsapp.$error">
                Campo obrigatório
              </p>
            </div>
            <div>
              <label for="assistente">Status whatsapp</label>
              <select :class="[{ error: v$.assistente.$error }]" name="assistente" id="assistente" tabindex="0"
                v-model="v$.assistente.$model">
                <option disabled value="">Ativado/Desativado</option>
                <option value="Ativado" tabindex="1">Ativado</option>
                <option value="Desativado" tabindex="2">Desativado</option>
              </select>
              <p class="style-obgr" v-if="v$.assistente.$error">
                Campo obrigatório
              </p>
            </div>
            <div style="display: flex; flex-direction: column; height: 30%; gap: 30px">
              <div>
                <label>Nome do expediente</label>
                <SelectDatasend style="z-index: 10" :options="filtrarexpediente" :addExpediente="addExpediente"
                  :selected="expedienteTitle" :expedienteRemove="expedienteRemove" @input="updateExpedienteTitle">
                </SelectDatasend>
              </div>
            </div>
            <div class="botao_popup" v-if="filtro.length > 0" @click="enviarCadastroParaBancoViaDisparo()">
              Confirmar alterações
            </div>
            <div class="input_button" id="enviar-dados" @click="validar_cadastro">
              <span>Salvar / Fechar</span>
            </div>
          </div>
          <div class="main-list">
            <table>
              <thead class="header1">
                <tr class="labels">
                  <div class="icons">
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>
                      <button v-if="temItensSelecionados" class="button-disparar" @click="abrirModal(id)">
                        <i class="fa-solid fa-paper-plane"></i>
                        <div class="DicaTexto">Disparar agora</div>
                      </button>
                    </th>
                  </div>
                  <th class="space-labels">PAINEL</th>
                  <th class="space-labels">PASTA</th>
                  <th class="space-labels">OBJETO</th>
                  <th class="space-labels" id="bookstyle" :class="{ styleBook: blockBookmark }">
                    MARCADO
                  </th>
                  <th id="campStyle" class="space-labels" :class="{ styleCampo: blockFieldAndContent }">
                    CAMPO
                  </th>
                  <th id="contStyle" class="space-labels" :class="{ styleCont: blockFieldAndContent }">
                    CONTEÚDO
                  </th>
                  <th class="style-adc"></th>
                </tr>
                <tr class="selecteds">
                  <th class="icons">
                  <th>
                    <input type="checkbox" v-model="todos_itens" @click="selecionarTodos" />
                  </th>
                  <th>
                    <div class="style-multiSe" v-if="filtro.length > 0">
                      <MultiSelectVue style="font-weight: 300;" @click="pagesDropdownOpen = !pagesDropdownOpen"
                        :filtros="filtro" @selectedOption="marcaSelecionado">
                      </MultiSelectVue>
                      <div class="DicaTexto">Flegar filtros</div>
                    </div>
                  </th>
                  <th class="calendar-style">
                    <i id="icon" class="fa-solid fa-calendar-days"></i>
                    <div class="DicaTexto">Agendar filtro</div>
                  </th>
                  <th class="favori-style">
                    <i id="icon" class="fa-solid fa-star"></i>
                    <div class="DicaTexto">Favoritar filtro</div>
                  </th>
                  <th class="favori-stylee">
                    <i id="icon" class="fa-solid fa-file-export"></i>
                    <div class="DicaTexto">Enviar filtro</div>
                  </th>
                  </th>
                  <th class="style-select">
                    <SelectSearch name="painel" tabindex="0" @update="
                      limparPastaEObjeto();
                    atualizarSheet();
                    getBookmark();
                    atualizarField();
                    " @executeSearch="verifyReceivedThetextSearch" :options="paineis" :selected="painelTitle"
                      @input="updatePainel" :removeSelecao="removeSelecao">
                    </SelectSearch>
                  </th>
                  <th class="style-select">
                    <SelectSearch name="sheet" tabindex="1" @update="
                      limparObjeto();
                    atualizarObject();
                    " @executeSearch="verifyReceivedThetextSearch" :options="sheets" :selected="sheetTitle"
                      :spinner="spinner" @input="updatePasta" @without-input="resetPasta"
                      :removeSelecao="removeSelecao">
                    </SelectSearch>
                  </th>
                  <th class="style-select">
                    <SelectSearch name="object" tabindex="2" @executeSearch="verifyReceivedThetextSearch"
                      :options="objetos" :selected="objetoTitle" :spinner="spinner" @input="updateObjeto"
                      @without-input="resetObjeto" :removeSelecao="removeSelecao">
                    </SelectSearch>
                  </th>
                  <th class="style-select">
                    <SelectSearch name="bookmark" tabindex="2" :selected="bookmarkTitle" :spinner="spinner" @update="
                      getBookmark();
                    limparBookmark();
                    " @input="updateBookmark" :options="bookmarks" @without-input="resetBookmark"
                      @executeSearch="verifyReceivedThetextSearch" :abrirselect="blockBookmark">
                    </SelectSearch>
                  </th>
                  <th class="style-select">
                    <SelectSearch name="campo" tabindex="2" @update="
                      getContent();
                    liparConteudo();
                    " @executeSearch="verifyReceivedThetextSearch" :options="campos" :selected="campoTitle"
                      :spinner="spinner" @input="updateCampo" @without-input="resetCampo"
                      :abrirselect="blockFieldAndContent" :removeSelecao="removeSelecao">
                    </SelectSearch>
                  </th>
                  <th class="style-select">
                    <SelectSearch name="conteudo" tabindex="2" :options="conteudos" :selected="conteudoTitle"
                      :spinner="spinner" @executeSearch="verifyReceivedThetextSearch" @input="updateConteudo"
                      :showLoadButton="showLoadButton" @obterMaisConteudo="obterMaisConteudos" :loading="loading"
                      @without-input="resetConteudo" :abrirselect="blockFieldAndContent" :removeSelecao="removeSelecao">
                    </SelectSearch>
                  </th>
                  <th>
                    <div class="style-adc">
                      <button id="button-add" v-if="!temItensSelecionados" @click="adcionar">
                        <i class="fa-solid fa-circle-plus"></i>
                        <div class="DicaTexto">Adicionar filtro</div>
                      </button>
                      <button id="button-excluir" v-if="temItensSelecionados" @click="excluirSelecionados">
                        <i class="fa-solid fa-trash"></i>
                      </button>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody class="lista-container">
                <tr class="mutavel" v-for="(it, index) in filtro" :key="index" :ref="'item_' + index">
                  <div class="icons">
                    <td>
                      <input v-if="it.idLine" type="checkbox" v-model="it.selecionado"
                        @change="atualizarItensSelecionados()" />
                    </td>
                    <td>
                      <div @click="
                        abrirPopup('Popup_id_' + index, it.idLine, it.sheet.title)
                        " id="icon-calendar">
                        <div v-if="it.idLine">
                          <div v-if="it.tem_disparo">
                            <i class="fa-solid fa-calendar-days" style="color: #2bb542" :id="'Popup_id_' + index"
                              :class="{ calendar: mostrarCalendar }"></i>
                          </div>
                          <div v-else>
                            <i class="fa-solid fa-calendar-days" style="color: #929090" :id="'Popup_id_' + index"
                              :class="{ calendar: mostrarCalendar }"></i>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div @click="addedFavorite(it.idLine, it.sheet.title)" id="icon-facorite">
                        <div v-if="it.idLine">
                          <div v-if="it.favorito">
                            <i class="fa-solid fa-star" style="color: #2bb542; cursor: pointer"
                              :id="'icon' + index"></i>
                          </div>
                          <div v-else>
                            <i class="fa-solid fa-star" style="color: #929090; cursor: pointer"
                              :id="'icon' + index"></i>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="style-muls" v-if="it.sheet.id">
                        <div class="style-muls" v-if="!it.objeto.id">
                          <i style="color: #2bb542" class="fa-solid fa-file-pdf"></i>
                        </div>
                        <div class="style-iconType" v-else>
                          <i style="color: #2bb542" :class="it.icon"></i>
                          <div v-if="filtro.length > 0">
                            <MultiDatasend class="style-muls" @click="DropdownOpen = !DropdownOpen"
                              @selectedOpt="opcaoSelecionadaEnvio" @updateDataInArray="updateIcon" :type="it.tipo"
                              :index="index" :display="display" :total="filtro.length"></MultiDatasend>
                          </div>
                        </div>
                      </div>
                      <div class="style-muls" v-else>
                        <i style="color: #2bb542" class="fa-solid fa-file-circle-xmark"></i>
                      </div>
                    </td>
                  </div>
                  <td class="style-mutavel">{{ it.painel.title }}</td>
                  <td class="style-mutavel">{{ it.sheet.title }}</td>
                  <td class="style-mutavel">{{ it.objeto.title }}</td>
                  <td class="style-mutavel">{{ it.bookmark.title }}</td>
                  <td class="style-mutavel">
                    <input class="style-inputs-mutavel" :value="it.campo.title"
                      @change.prevent="editCampo(index, $event)"
                      v-if="it.campo.title !== null && it.conteudo.title !== null" />
                    <p class="style-p" v-else>*****</p>
                  </td>
                  <td class="style-mutavel">
                    <input class="style-inputs-mutavel" :value="it.conteudo.title"
                      @change.prevent="editConteudo(index, $event)"
                      v-if="it.campo.title !== null && it.conteudo.title !== null" />
                    <p class="style-p" v-else>*****</p>
                  </td>
                  <td>
                    <div class="style-adc">
                      <button id="button-remove" @click="remover_filtro(index)">
                        <i id="icon" class="fa-solid fa-xmark"></i>
                        <div class="DicaTexto">Excluir filtro</div>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <popupDatasend class="style-poupdata" v-if="mostrarPoup" :removerFiltros="revoverFiltros"
              @excluirSelecionados="excluirSelecionados" @fecharComponente="fecharPopupDatasend"></popupDatasend>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<style scoped>
.style-mutavel {
  width: 10.5vw;
  padding: 0vw 0.8vw;
  font-size: 1vw;
}

.style-iconType {
  display: flex;
  width: 100%;
}

.style-select {
  font-weight: 300;
}

.style-muls {
  margin-left: 0.2rem;
}

.calendar {
  display: block;
  color: #0f9e27;
  cursor: pointer;
}

.style-adc {
  display: flex;
  background-color: transparent;
  width: 2vw;
}

.style-obgr {
  color: red;
  padding: 2px;
  margin-left: 1px;
}

.space-fav {
  display: flex;
  width: 35px;
  min-width: 1.6rem;
  max-width: 1.6rem;
}

.space-envio {
  display: flex;
  min-width: 1.6rem;
  max-width: 3rem;
}

.space-calen {
  display: flex;
  width: 2rem;
  min-width: 1.6rem;
  max-width: 1.6rem;
}

.space-calen i {
  width: 100%;
}

.space-style {
  width: 1em;
  align-items: center;
  font-size: 1.2rem;
}

.style-multiselect {
  z-index: 10;
}

.style-inputs-mutavel {
  width: 9.5vw;
  height: 2rem;
  border-radius: 7px;
}

#campStyle.styleCampo {
  color: rgba(185, 184, 184, 0.747);
}

#contStyle.styleCont {
  color: rgba(185, 184, 184, 0.747);
}

#bookstyle.styleBook {
  color: rgba(185, 184, 184, 0.747);
}

.all-style {
  width: 70vw;
  justify-content: space-around;
  flex-direction: row;
  border-bottom: 1px solid rgba(185, 184, 184, 0.747);
}

.button-disparar {
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: #1ab434;
  height: 15px;
  width: 15px;
  display: flex;
}

#button-excluir {
  width: 100%;
  color: red;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 1.1rem;
}

.style-multiSe {
  position: relative;
}

.style-multiSe:hover .DicaTexto {
  visibility: visible;
}

#button-excluir:hover .DicaTexto {
  visibility: visible;
}

.button-disparar {
  position: relative;
}

.button-disparar:hover .DicaTexto {
  visibility: visible;
}

.input_button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: bolder;
  height: 30px;
  border-radius: 2px;
  padding-left: 5px;
  text-align: center;
}

.input_button:hover {
  background-color: rgba(22, 24, 22, 0.681);
}

#assistente {
  height: 2.7vw;
}

.icon-style {
  background-color: transparent;
  border: none;
  font-size: 0.8rem;
  margin-left: -4px;
  cursor: pointer;
}

.style-poupdata {
  margin-left: 1.5vw;
}

.inputs {
  width: 80%;
  display: flex;
  width: 20em;
  align-items: center;
  min-width: 6.5rem;
}

.button-excluir {
  color: white;
  cursor: pointer;
  border: transparent;
  background-color: red;
  border-radius: 3px;
  height: 30px;
  width: 70px;
}

#Mensagem {
  margin-top: -30px;
  margin-right: -300px;
}

.select-style {
  width: 10px;
}

#icon-calendar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1em;
  height: 1em;
  margin-left: 6px;
}

#icon-add {
  height: 18px;
  width: 18px;
  margin-left: -3px;
  font-size: 1.1rem;
}

.style-painel {
  margin-left: 4vw;
}

.style-pasta {
  margin-left: 2vw;
}

.style-objeto {
  margin-left: 2vw;
}

#icon-facorite {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1em;
  height: 1.3em;
  margin-left: 6px;
}

.space-labels {
  width: 9.5vw;
  font-size: 1.1vw;
  font-weight: 300;
}

.btn-info {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: green;
  font-size: 1.1rem;
  margin-left: 67vw;
}

.header2 select {
  /* width: 12vw; */
  height: 2rem;
  font-size: 1rem;
  color: #929090;
  border: 1px solid #929090;
  border-radius: 5px;
  padding-left: 5px;
}

.header2 input {
  /* width: 12vw; */
  height: 2rem;
  font-size: 1rem;
  color: #929090;
  border: 1px solid #929090;
  border-radius: 5px;
  padding-left: 1px;
  margin-left: -4px;
}

.mutavel {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #929090;
}

.mutavel:hover {
  box-shadow: 1px 1px 5.5px #8b8b8b;
}

.mutavel p {
  display: flex;
}

.mutavel input {
  border: 1px solid #929090;
}

#button-add {
  width: 100%;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #2bb542;
  font-size: 1.2vw;
}

#button-remove {
  width: 100%;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: red;
  font-size: 1.2vw;
}

.line {
  background: #b3b3b3;
  width: 100%;
  height: 2px;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
}

.forms {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 40%;
  max-width: 300px;
  height: 90%;
}

.forms label {
  font-size: 1.1rem;
}

.forms select {
  font-size: 1.1rem;
  padding-left: 5px;
  width: 90%;
  height: 40em;
  border: 1px solid #b3b3b3;
  border-radius: 10px;
}

.forms input {
  width: 90%;
  height: 40px;
  color: black;
  font-size: 1.1rem;
  padding-left: 5px;
  border: 1px solid #b3b3b3;
  border-radius: 10px;
}

.main-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90%;
  box-shadow: 2px 2px 4px 4px rgb(217, 217, 217);
  border-radius: 5px;
  align-items: center;
  /* overflow-x: hidden; */
}

.main-list table {
  display: flex;
  flex-direction: column;
  width: 97%;
  height: 100%;
}

.header1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
  color: #2bb542;
  border-bottom: 1px solid #929090;
  padding-bottom: 0.3em;
}

.labels {
  display: flex;
  width: 100%;
  height: 1.5em;
  margin-top: 0.5em;
  justify-content: space-between;
  align-items: center;
}

.selecteds {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.icons {
  display: flex;
  width: 5em;
  justify-content: space-between;
  align-items: center;
}

.lista-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  overflow-x: hidden;

}

main {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Cor de fundo semitransparente */
  /* font-family: Arial, Helvetica, sans-serif; */
}

.cadastrousuario {
  border-radius: 10px;
  display: flex;
  position: absolute;
  width: 95vw;
  height: 90vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
}

.body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.cabecario {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 8vh;
  font-size: 1vw;
}

.informations {
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 1em;
}

.botao_popup {
  width: 90%;
  border: none;
  color: black;
  background-color: white;
  height: 40px;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #b3b3b3;
  font-weight: bold;
  border-radius: 5px;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.botao_popup:hover {
  background-color: rgba(92, 90, 90, 0.1);
}

#adicionar-filtro {
  width: 100px;
  height: 30px;
  background-color: #2bb542;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  transition: 0.5s;
  margin-left: 10px;
}

#adicionar-filtro:hover {
  background-color: #0f9e27;
}

#enviar-dados {
  width: 90%;
  height: 40px;
  background-color: #2bb542;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  transition: 0.5s;
}

#enviar-dados:hover {
  background-color: #2bb542c6;
}

.btn-voltar {
  background-color: white;
  border: none;
  cursor: pointer;
  color: rgba(18, 17, 17, 0.867);
  font-size: 1.3vw;
}

#button-add {
  position: relative;
}

#button-add:hover .DicaTexto {
  visibility: visible;
}

.calendar-style:hover .DicaTexto {
  visibility: visible;
}

.calendar-style {
  position: relative;
}

.favori-style:hover .DicaTexto {
  visibility: visible;
}

.favori-style {
  position: relative;
  margin-left: 2px;
}

.favori-stylee:hover .DicaTexto {
  visibility: visible;
}

.favori-stylee {
  position: relative;
}

#button-load {
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: green;
  margin-left: -5px;
}

#button-load:hover .DicaTexto {
  visibility: visible;
}

.DicaTexto {
  position: absolute;
  visibility: hidden;
  font-size: 13px;
  width: 100px;
  background: rgba(37, 37, 37, 0.695);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 4px;
  bottom: 100%;
  left: 50%;
  margin-left: -50px;
  margin-bottom: 5px;
  z-index: 10;
  /* transition-timing-function: ease;
  transition-duration: 0.2s; */
}
</style>